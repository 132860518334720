// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";
.avatar-custom {
  width: 100px !important;
  height: 100px !important;
}

.avatar-custom-img {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
  object-fit: cover;
}

.it .btn-orange {
  background-color: blue;
  border-color: #777 !important;
  color: #777;
  text-align: left;
  width: 100%;
}
.it input.form-control {
  border: none;
  margin-bottom: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
}
.it .form-control:focus {
  border-color: #ff4d0d;
  box-shadow: none;
  outline: none;
}
.fileUpload {
  position: relative;
  overflow: hidden;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.sound-wave {
  width: 100%;
}
.content_ {
  width: 50%;
  background-color: #212121;
  margin: 0 auto;
}

.start-record {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 1rem 1rem 0;
  background-color: #fff;
  transition: 0.5s ease-in-out;
}

.start-record:hover {
  background-color: red;
  color: #fff;
}

.stop-record {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 0 1rem 1rem;
  background-color: #fff;
  transition: 0.5s ease-in-out;
}
.stop-record:hover {
  background-color: red;
  color: #fff;
}

.record-button {
  display: flex;
  justify-content: center;
}

.remove {
  color: blue;
  cursor: pointer;
  font-size: 18px;
}



.content-pitch {
  display: flex;
  align-items: center;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.my-picker {
   height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input[type=time]::-webkit-datetime-edit-ampm-field {
  color: transparent;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}