// Here you can add other styles
table {
    th , td{
        border-left: 1px solid #d8dbe0;
        border-right: 1px solid #d8dbe0;
        border-bottom: 1px solid #d8dbe0;
        vertical-align: middle!important;
        text-align: center
    }
}

.c-switch-label.c-switch-lg .c-switch-slider {
    width: 100px
}
.c-switch-label.c-switch-lg .c-switch-input:checked ~ .c-switch-slider::before {
    transform: translateX(65px);
}

.c-switch-success .c-switch-input + .c-switch-slider {
    background-color: #2eb85c;
}
.c-switch-label.c-switch-lg .c-switch-slider::after {
    color: #fff;
}
.c-switch-slider {
    background-color: grey;
}
.c-switch-input:focus ~ .c-switch-slider {
    background-color: grey;
    box-shadow: none;
}
.swtich-pick {
    margin-right: 15px!important;
    padding-top: 10px;
}